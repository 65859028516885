import React from 'react';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/icons';
import style from './FooterSocial.module.scss';
import Link from 'next/link';

export const FooterSocial = () => {
  return (
    <div className={style.socialContainer}>
      <div className={style.poweredWrapper}>
        {' '}
        <p className="text-white">Powered by</p>
        <Button variant="link" className="p-0" asChild>
          <a className="text-white" href="https://orcatec.com" target="_blank">
            <Icons.orcatecIcon /> Orcatec
          </a>
        </Button>
      </div>
      <div className={style.iconsWrapper}>
        {/* <div className="ml-4 cursor-pointer">
          <Link href={'/admin/login'}>
            <Icons.login />
          </Link>
        </div> */}

        <Link
          target="_blank"
          href={'https://www.instagram.com/tytum_hvac/'}
          aria-label="Instagram"
        >
          <Icons.instagramIcon />
        </Link>
        <Link
          aria-label="Facebook"
          target="_blank"
          href={
            'https://www.facebook.com/people/Tytum-Heating-and-AC/61559727104902/'
          }
        >
          <Icons.facebookIcon />
        </Link>
      </div>
    </div>
  );
};
