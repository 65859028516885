'use client';
import React, { useState, useRef, useEffect } from 'react';
import { usePathname } from 'next/navigation'; // To get the current path
import style from './NavItem.module.scss';
import { SingleItem } from './components/SingleItem';
import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';

interface MenuItem {
  label: string;
  path?: string;
  children?: MenuItem[];
}

interface NavItemProps {
  items: MenuItem;
}

export const NavItem: React.FC<NavItemProps> = ({ items }) => {
  const pathname = usePathname();
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
  const hoverTimeouts = useRef<Record<string, NodeJS.Timeout | null>>({});

  const toggleMenu = (key: string, value?: boolean) => {
    setOpenMenus((prev) => ({
      ...prev,
      [key]: value !== undefined ? value : !prev[key],
    }));
  };

  const isItemActive = (item: MenuItem): boolean => {
    if (item.path && pathname.startsWith(item.path)) {
      return true;
    }

    if (item.children) {
      return item.children.some((child) => isItemActive(child));
    }
    return false;
  };

  useEffect(() => {
    // Close all menus on pathname change
    setOpenMenus({});
  }, [pathname]);

  const handleMouseEnter = (key: string) => {
    if (hoverTimeouts.current[key]) {
      clearTimeout(hoverTimeouts.current[key]!);
    }
    toggleMenu(key, true);
  };

  const handleMouseLeave = (key: string) => {
    hoverTimeouts.current[key] = setTimeout(() => {
      toggleMenu(key, false);
    }, 100); // Adjust delay for smoother UX
  };

  const renderItems = (menuItems: MenuItem[], level = 0) => {
    return (
      <ul className={cn(style.navItemsList, level > 0 && style.submenu)}>
        {menuItems.map((item, index) => {
          const key = `${level}-${index}`;
          const hasChildren = item.children && item.children.length > 0;
          const isActive = isItemActive(item);

          return (
            <li
              key={key}
              className={cn(
                style.navItem,
                isActive && level === 0
                  ? 'rounded-[4px] border border-black'
                  : 'border border-transparent'
              )}
              onMouseEnter={() => hasChildren && handleMouseEnter(key)}
              onMouseLeave={() => hasChildren && handleMouseLeave(key)}
            >
              {hasChildren ? (
                <>
                  <button className="flex w-full items-center text-left">
                    <SingleItem item={item} />
                    <Icons.arrowDown isOpen={openMenus[key]} />
                  </button>
                  {openMenus[key] && (
                    <ul className={style.submenu}>
                      {renderItems(item.children!, level + 1)}
                    </ul>
                  )}
                </>
              ) : (
                <a href={item.url || '#'} className="block">
                  <SingleItem item={item} />
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return <div>{renderItems([items])}</div>;
};
