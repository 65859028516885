// SideMenu.tsx
'use client';
import React, { useRef, useState } from 'react';
import { cn } from '@/lib/utils';

import { Icons } from '@/components/icons';
import { menuItems } from '@/constants';
import { SingleItem } from '../NavItem/components/SingleItem';
import Button from '@/components/ui/Button/Button';
import { usePathname } from 'next/navigation';
import { useModalContext } from '@/context/ModalsContext';

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const ref = useRef<HTMLUListElement>(null);
  const pathname = usePathname();
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
  const { setIsModalOpened } = useModalContext();

  const toggleMenu = (key: string) => {
    setOpenMenus((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const isItemActive = (item: MenuItem): boolean => {
    if (item.path && pathname.startsWith(item.path)) {
      return true;
    }

    if (item.children) {
      return item.children.some((child) => isItemActive(child));
    }
    return false;
  };

  const renderItems = (menuItems, level = 0) => {
    return (
      <ul>
        {[{ label: 'Home', path: '/', children: [] }, ...menuItems].map(
          (item, index) => {
            const key = `${level}-${index}`;
            const hasChildren = item.children && item.children.length > 0;

            const isActive = isItemActive(item);

            return (
              <li key={key} className={cn(`ml-${level + 2} relative my-3`)}>
                {hasChildren ? (
                  <>
                    <button
                      onClick={() => toggleMenu(key)}
                      className={cn(
                        `hover:opacity-1 flex w-full items-center  justify-between text-left`
                      )}
                    >
                      <SingleItem
                        item={item}
                        isActive={isActive}
                        onClick={onClose}
                      />
                      <Icons.arrowDown isOpen={openMenus[key]} />
                    </button>
                    {openMenus[key] && (
                      <ul ref={ref}>{renderItems(item.children, level + 1)}</ul>
                    )}
                  </>
                ) : (
                  <div className="text-left ">
                    <SingleItem
                      isActive={item.path === '/' ? pathname === '/' : isActive}
                      item={item}
                      onClick={onClose}
                    />
                  </div>
                )}
              </li>
            );
          }
        )}
      </ul>
    );
  };
  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-50 size-full bg-black bg-opacity-50 transition-transform duration-300',
        isOpen ? 'translate-x-0' : '-translate-x-full'
      )}
    >
      <div className="h-full w-80 bg-white p-6 shadow-lg">
        <div className="flex justify-end">
          <button onClick={onClose} aria-label="Close Menu">
            <Icons.close />
          </button>
        </div>

        <div className="flex h-full flex-col">
          <nav className="mt-8">{renderItems(menuItems)}</nav>

          <div className="mb-6 mt-auto text-left">
            Call us:{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              href={`tel:+16193494397`}
              className="font-medium"
            >
              (619) 349-4397
            </a>
          </div>

          <div className="mb-10 flex items-center">
            <Button onClick={() => setIsModalOpened(true)} variant={'main'}>
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
